import { Link } from "react-router-dom";
import { motion } from "framer-motion";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

const ServicesSection = () => {
  // useEffect(() => {
  //   gsap.from(".service-item", {
  //     opacity: 0,
  //     y: 50,
  //     duration: 1,
  //     scrollTrigger: {
  //       trigger: ".service-item",
  //       start: "top 90%",
  //       end: "bottom 10%",
  //       scrub: 1,
  //     },
  //   });
  // }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="page"
    >
      <section className="mt-5 p-10">
        <h2 className="text-xl font-bold mb-10">Servizi Offerti</h2>

        <div className="bg-[#efd2d6] w-fit mx-auto p-4 rounded">
          <h1 className="text-lg font-bold text-primarypink mb-5">
            Creazione Siti Web
          </h1>
          <ul>
            <li className="service-item">
              <strong>Sito Vetrina Base:</strong> Pagina principale, pagina di servizio, contatti
            </li>
            <li>Logo</li>
            <li>Responsive design</li>
            <li>Integrazione di social media e mappe</li>
            <li>Ottimizzazione per SEO di base</li>
            <li>Supporto e assistenza post-lancio limitati</li>
            <li className="mt-10">
              <strong>Sito Vetrina Avanzato:</strong> Oltre alle funzionalità del pacchetto base:
            </li>
            <li>Aggiunta di gallerie, blog o sezioni dinamiche.</li>
            <li>Personalizzazione avanzata del design e ottimizazione SEO più approfondita con l'ausilio di professionisti del settore.</li>
          </ul>
          <h1 className="text-lg font-bold text-primarypink mt-10">
            Creiamo insieme il tuo progetto!
          </h1>
          <div>
            Sarei entusiasta di conoscere la tua visione e offrirti un preventivo personalizzato.<br></br>
            <Link to="/contatti">
              <h1 className="text-lg font-bold text-primarypink mt-3">
                <i className="fi fi-rr-cursor-finger"></i> Non esitare a contattarmi! :)
              </h1>
            </Link>
          </div>
        </div>

        <div className="bg-[#efd2d6] w-fit mx-auto p-4 rounded mt-20">
          <h3 className="text-lg font-bold text-primarypink mb-5">
            Collaboriamo!
          </h3>
          <div className="service-item">
            Sarei lieta di partecipare a progetti già in corso o collaborare con team creativi.<br></br>
            Se stai cercando un supporto aggiuntivo o nuove idee per il tuo progetto, mi rendo disponibile ad aiutarti.<br></br>
            La mia continua formazione in sviluppo web e la mia passione per la grafica e la scrittura, potrebbero portare un valore aggiunto al tuo lavoro.
            <br></br>
            <Link to="/contatti">
              <h1 className="text-lg font-bold text-primarypink mt-3">
                <i className="fi fi-rr-cursor-finger"></i> Contattami per scoprire come posso contribuire al tuo progetto per farlo crescere insieme.
              </h1>
            </Link>
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default ServicesSection;
